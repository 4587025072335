<template>
  <nav v-if="!hideNavbar">
  <ul>
    <li><router-link to="/"><img src="./assets/logo2.png" class="logounav"></router-link></li>
  </ul>
  <ul>
    <li v-if="windowWidth > 768"><router-link to="/">{{$t('home')}}</router-link></li>
    <li v-if="windowWidth > 768"><router-link to="/projects">{{$t('projects')}}</router-link></li>
    <li summary v-if="windowWidth <= 768">
      <details class="dropdown dde">
        <summary v-if="windowWidth <= 768">
          Menu
        </summary>
        <ul dir="rtl">
          <li v-if="windowWidth <= 768"><router-link to="/">{{$t('home')}}</router-link></li>
          <li v-if="windowWidth <= 768"><router-link to="/projects">{{$t('projects')}}</router-link></li>
          <li v-if="windowWidth <= 768"><router-link to="/catalogue">{{$t('catalogue')}}</router-link></li>
        </ul>
      </details>
    </li>

    <li>
      <details class="dropdown dde">
        <summary >
          {{$t('language')}}
        </summary>
        <ul dir="rtl">
          <li @click="setENG()"><span class="langtext">English  </span><img src="../src/assets/eng.png" width="25" height="25"></li>
          <li @click="setSHQ()"><span class="langtext">Shqip  </span><img src="../src/assets/alb.png" width="25" height="25"></li>
          <li @click="setBOS()"><span class="langtext">Bosnski  </span><img src="../src/assets/bos.png" width="25" height="25"></li>
        </ul>
      </details>
    </li>
  </ul>
  
</nav>
  <router-view/>
</template>

<script>


export default {
  data() {
    return {
      windowWidth: 0
    };
  },
  setup() {

  },
  mounted() {
    console.log(route)
    // Postavljanje trenutne širine prozora
    this.windowWidth = window.innerWidth;
    
    // Event listener za promenu veličine prozora
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    // Uklanjanje event listener-a kada komponenta bude uništena
    window.removeEventListener('resize', this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    setENG(){
      localStorage.removeItem("language");
      localStorage.setItem("language", "ENG");
      location.reload();
    },
    setSHQ(){
      localStorage.removeItem("language");
      localStorage.setItem("language", "SHQ");
      location.reload();
    },
    setBOS(){
      localStorage.removeItem("language");
      localStorage.setItem("language", "BOS");
      location.reload();
    }
  },
  computed: {
  hideNavbar() {
    return this.$route.path === "/catalogue";
  }
}


};
function setLanguage() {
  const data = localStorage.getItem("language");
  if (data === null) {
    localStorage.setItem("language", "ENG");
  }
}
setLanguage();
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.langtext{
  font-size: 15px;
}

nav summary{
  background-color: #1a1a1a !important;
}

.dde{
  cursor: pointer;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #7c6851;

    &.router-link-exact-active {
      color: #fcd3a7;
    }
  }
}

@media (max-width: 480px) {
  .logounav{
    width: 150px;
  }
  .langtext{
  font-size: 12px;
}
}

@media (max-width: 768px) {
  nav summary{
  background-color: #1a1a1a !important;
  
}

.langtext{
  font-size: 12px;
}
  .logounav{
    margin-left: -20px;
    width: 125px;
  }
}
</style>
