<template>
    <div class="carousel-container">
      <h2>{{$t('ourhappyclients')}}</h2>
      <div class="carousel">
        <div class="carousel-track">
  
          <div v-for="(client, index) in displayedClients" :key="index" class="carousel-item">
            <img :src="client" alt="Client logo" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        clients: [
          // "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/clients%2Fzhuri.png?alt=media&token=5d96c426-a023-4a3a-b2c6-af04edb061a7",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/clients%2Fflux-white.png?alt=media&token=cc5b6a76-5d0b-4b31-8188-c124eadb8807",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/output-onlinepngtools%20(5).png?alt=media&token=4960ce42-7335-411f-8487-c2b994bc3352",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/clients%2Fsuncano-belo.png?alt=media&token=af41adbb-27d4-4f5a-8988-86bd0e258874",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/dalas-Photoroom.png?alt=media&token=890c431c-7a48-417b-b7b9-e8d8963e1673",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/abazaj-removebg-preview.png?alt=media&token=c0cac5c2-04bb-4ad6-995a-8d78ef8ba56c",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/monibau.png?alt=media&token=d4e689c2-aaef-40b0-b8b0-bba7d519ffd6",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/clients%2Flogo_via.png?alt=media&token=eedb4a8a-541b-42db-9f50-3832a7db040a",
          "https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/clients%2Frenual.png?alt=media&token=6b7e7020-e3c5-4ee0-9d3a-048dde937f9f",
        ],
      };
    },
    computed: {
      // Dupliramo niz klijenata da bismo napravili efekt loopa
      displayedClients() {
        return [...this.clients, ...this.clients];
      },
    },
  };
  </script>
  
  <style scoped >
  .carousel-container {
  text-align: left; /* Poravnanje teksta na levo */
  margin-top: 20px;
}

h2 {
  color: #ebe9e9;
  margin-left: 43%;
  font-size: 1.5em;
  margin-bottom: 20px;
  padding-bottom: 50px;
}

.carousel {
  overflow: hidden;
  width: 100%; /* Širina u odnosu na ekran */
  margin: 0;
  border-radius: 10px;
}

.carousel-track {
  display: flex;
  justify-content: flex-start; /* Poravnanje na levo */
  width: max-content; /* Traka sa širinom koja raste koliko je potrebno */
  animation: scroll 20s linear infinite; /* Glatka animacija bez resetovanja */
}

.carousel-item {
  flex: 0 0 auto;
  width: 200px; /* Veći logotipi */
  padding: 10px;
}

.carousel-item img {
  width: 150px;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.carousel-item img:hover {
  opacity: 1; /* Vidljivost 100% na hover */
}

/* Animacija za beskonačnu traku koja ulazi s jedne i izlazi s druge strane */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}



/* Prilagođavanje za vrlo male uređaje (npr. mobilni telefoni ispod 480px) */
@media (max-width: 480px) {
  h2 {
    margin-left: 35%; /* Dalje centriranje naslova za još manje ekrane */
    font-size: 1.1em;
    padding-bottom: 10px;
  }

  .carousel-item {
    width: 100px; /* Još manji logotipi */
    padding: 3px;
  }

  .carousel-item img {
    max-width: 100px; /* Još manje logotipi */
    max-height: 80px;
  }
}

  </style>
  